<template>
  <div class="w-100">
    <Password />
  </div>
</template>

<script>
import Password from '@/components/profile/settings/password/Index.vue'

export default {
  name: 'PasswordPage',
  components: { Password }
}
</script>
