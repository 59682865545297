import API from '../utils/API'

export default {
  getUserOrderHistory (data) {
    return API({
      url: 'userHistory',
      data,
      method: 'POST',
      requestId: 'userHistory'
    })
  },
  changePassword (data) {
    return API({
      url: 'changePassword',
      data,
      method: 'POST',
      requestId: 'changePassword'
    })
  }
}
