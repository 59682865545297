<template>
  <div class="password">
    <div class="row">
      <div class="col-xl-6 col-lg-7">
        <h3 class="bold-font fs-20 black-color-text m-b-10">
          {{ $t('forms.password') }}
        </h3>
        <p class="fs-16 light-gray-color-text">
          {{ $t('forms.resetPassText') }}
        </p>
        <div class="row">
          <!-- <form @submit.prevent=""> -->
          <div class="col-md-12">
            <div class="show-hide-password-container">
              <BaseTextInput
                :value="form.oldPassword"
                input-id="oldPassword"
                :label="$t('forms.oldPassword')"
                class="m-t-18 old-password"
                :type="oldPassFieldType"
                :has-error="errors.has('oldPassword')"
                :error-position="'bottom'"
                :message="errors.get('oldPassword')"
                @reset="errors.clear()"
                @submit="validateData"
                @update-value="onChangePassword"
              />
              <button
                class="show-profile-password"
                type="button"
                @click.prevent="onSwitchOldPass"
              >
                <OpenEye v-if="oldPassFieldType == 'text'" />
                <ClosedEye v-else />
              </button>
            </div>
          </div>

          <div class="col-md-6">
            <div class="show-hide-password-container">
              <BaseTextInput
                :value="form.newPassword"
                input-id="newPassword"
                class="m-t-18"
                :type="newPassFieldType"
                :label="$t('forms.newPassword')"
                :has-error="errors.has('newPassword')"
                :message="errors.get('newPassword')"
                :error-position="'bottom'"
                @reset="errors.clear()"
                @submit="validateData"
                @update-value="onChangePassword"
              />
              <button
                class="show-profile-password"
                type="button"
                @click.prevent="onSwitchNewPass"
              >
                <OpenEye v-if="newPassFieldType == 'text'" />
                <ClosedEye v-else />
              </button>
            </div>
          </div>

          <div class="col-md-6">
            <div class="show-hide-password-container">
              <BaseTextInput
                :value="form.confirmPassword"
                input-id="confirmPassword"
                class="m-t-18"
                :type="newPassFieldType"
                :label="$t('forms.confirmPassword')"
                :has-error="errors.has('confirmPassword')"
                :message="errors.get('confirmPassword')"
                :error-position="'bottom'"
                @reset="errors.clear()"
                @submit="validateData"
                @update-value="onChangePassword"
              />
              <button
                class="show-profile-password"
                type="button"
                @click.prevent="onSwitchNewPass"
              >
                <OpenEye v-if="newPassFieldType == 'text'" />
                <ClosedEye v-else />
              </button>
            </div>
          </div>

          <div class="col-12">
            <base-button
              :text="$t('forms.save')"
              bg-color-class="main-color-bg"
              is-medium
              class="m-t-18"
              @action="changePassword"
            />
          </div>
          <!-- </form> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Form from '@/services/Form'
import Errors from '@/services/Errors'
import userSetting from '../../../../services/userSetting'
import OpenEye from '../../../icons/OpenEye.vue'
import ClosedEye from '../../../icons/ClosedEye.vue'
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
  name: 'Password',
  components: { ClosedEye, OpenEye },
  data () {
    return {
      form: new Form({
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      }),

      errors: new Errors(),
      oldPassFieldType: 'password',
      newPassFieldType: 'password'
    }
  },

  methods: {
    validateData () {
      let isValidated = true

      // const passRegex = /^.*(?=.{3,})(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\d\x]).*$/

      // if (!passRegex.test(this.form.oldPassword)) {
      //   this.errors.recordField({
      //     oldPassword: this.$t('forms.regExpressionPasswordValidation')
      //   })
      //   isValidated = false
      // }
      //
      // if (!passRegex.test(this.form.newPassword)) {
      //   this.errors.recordField({
      //     newPassword: this.$t('forms.regExpressionPasswordValidation')
      //   })
      //   isValidated = false
      // }
      //
      // if (!passRegex.test(this.form.confirmPassword)) {
      //   this.errors.recordField({
      //     confirmPassword: this.$t('forms.regExpressionPasswordValidation')
      //   })
      //   isValidated = false
      // }
      ///
      if (this.form.newPassword !== this.form.confirmPassword) {
        this.errors.recordField({
          newPassword: this.$t('forms.newPasswordExactConfirmation'),
          confirmPassword: this.$t('forms.confirmPasswordExactConfirmation')
        })
        isValidated = false
      }
      //
      if (this.form.oldPassword == '') {
        this.errors.recordField({
          oldPassword: this.$t('forms.emptyPasswordValidation')
        })
        isValidated = false
      }
      //
      if (this.form.newPassword == '') {
        this.errors.recordField({
          newPassword: this.$t('forms.emptyPasswordValidation')
        })
        isValidated = false
      }
      //
      if (this.form.confirmPassword == '') {
        this.errors.recordField({
          confirmPassword: this.$t('forms.emptyPasswordValidation')
        })
        isValidated = false
      }
      //
      // if (
      //   passRegex.test(this.form.newPassword) ===
      //     passRegex.test(this.form.confirmPassword) &&
      //   this.form.newPassword != '' &&
      //   this.form.oldPassword != '' &&
      //   this.form.oldPassword === passRegex.test(this.form.oldPassword)
      // ) {
      //   isValidated = true
      // }

      return isValidated
    },

    changePassword () {
      if (this.validateData()) {
        const payload = {
          token: this.getUserToken,
          old_password: this.form.oldPassword,
          password: this.form.newPassword,
          password_confirmation: this.form.confirmPassword
        }
        userSetting
          .changePassword(payload)
          .then((res) => {
            toast.success(res.data.message)
          })
          .catch((err) => {
            this.errors.recordField({
              oldPassword: err.response.data.message
            })

            // maybe we will use it later

            // if (err.response.status == 401) {
            //   this.errors.recordField({
            //     oldPassword: err.response.data.message
            //   })
            // }
            // if (err.response.status == 400) {
            //   this.errors.recordField({
            //     oldPassword: err.response.data.message[1]
            //   })
            // }
          })
      }
    },

    onChangePassword (val) {
      this.form[val.id] = val.value
    },

    onSwitchOldPass () {
      this.oldPassFieldType =
        this.oldPassFieldType === 'password' ? 'text' : 'password'
    },
    onSwitchNewPass () {
      this.newPassFieldType =
        this.newPassFieldType === 'password' ? 'text' : 'password'
    }
  }
}
</script>

<style lang="scss" scoped>
.base-text-input.m-t-18 {
  min-height: 110px;
}
</style>
